<template>
  <div class="BaseModular_all_wrap">
    <div class="BaseModular_wrap">
      <div class="BaseModular_subtitle" style="margin-bottom: 30px;display: flex;
    justify-content: space-evenly;
    align-items: center;">
		  <div style="width: 500px;height: 63px;">
		  	<img
		  	  src="@/static/common/zuo.png"
		  	/>
		  </div>
		  <div style="width: 12px;
height: 12px;
opacity: 1;
background: rgba(10, 96, 234, 1);">
		  	
		  </div>
	  {{ subTitle }}
	  <div style="width: 12px;
	  height: 12px;
	  opacity: 1;
	  background: rgba(10, 96, 234, 1);">
	  		  	
	  		  </div>
			  <div style="width: 500px;height: 63px;">
			  	<img
			  	  src="@/static/common/you.png"
			  	/>
			  </div>
			  </div>

      <!-- <div class="BaseModular_line">
        <div class="BaseModular_line_left"></div>
        <div class="BaseModular_line_right"></div>
      </div> -->

      <!-- <div class="BaseModular_title">
        {{ title }}
      </div> -->

      <slot />
    </div>
  </div>
</template>

<script>
import imgData from "@/data";
import { reactive } from "vue";
export default {
  props: {
    subTitle: {
      type: String,
      default: "货物服务采购信息",
      require: true,
    },
    title: {
      type: String,
      default:
        "公司善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的互联网产品",
    },
  },
  setup() {
    const ListData = [
      {
        title: "采购公告",
        code: "caigou",
      },
      {
        title: "变更公告 ",
        code: "biangeng",
      },
      {
        title: "答疑澄清",
        code: "dayi",
      },
      {
        title: "中标公告",
        code: "zhongbiao",
      },
      {
        title: "终止公告",
        code: "feibiao",
      },
      {
        title: "单一来源公示",
        code: "laiyuan",
      },
    ];

    let LiImgData = Object.values(imgData.PurchasingImg);
    LiImgData = LiImgData.map((v, index) => ({
      title:
        index == 0
          ? "水泥"
          : index == 1
          ? "钢筋"
          : index == 2
          ? "黄沙"
          : "门窗",
      url: v,
      subTitle:
        index == 0
          ? "文安县城发集团钢筋集中采购公告"
          : index == 1
          ? "文安县城发集团钢筋集中采购公告"
          : index == 2
          ? "文安县城发集团钢筋集中采购公告"
          : "文安县城发集团钢筋集中采购公告",
      status: "进行中",
      content:
        index == 0
          ? "文安县城发集团供配电工程设备采购预选招标—水泥制品采购项目进行公开招标项目供配电工程设备采购…"
          : "文安县城发集团水利工程已由南通市海门区人民政府同意建设，项目已具备招标条件，现对文安县城发集团水利工程进行公开招标…",
      price: "49800元",
      endtime: "2022-08-2",
      showRight: index == 0 ? true : false,
    }));

    const data = reactive({
      ListData,
      LiImgData,
    });
    return {
      ...data,
      ...imgData,
    };
  },
};
</script>

<style scoped>
@import url("./BaseModular.css");
</style>

